<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Create Practitioner</v-toolbar-title
      >

      <v-spacer />
      <v-btn class="ml-5" :loading="practitionerLoading" @click="submit()">
        Create Practitioner
      </v-btn>
    </v-app-bar>

    <v-form
      class="practitioner-form"
      @submit.prevent="isUpdating ? update() : submit()"
    >
      <v-row>
        <v-col cols="6">
          <v-text-field
            v-model.trim="form.name"
            class="input__outlined--regular"
            label="Practitioner Name"
            outlined
            :error-messages="form.$getError('name')"
            @input="form.$clearError('name')"
          />
          <v-text-field
            v-model.trim="form.specialisation"
            class="input__outlined--regular"
            label="Specialisation"
            outlined
            :error-messages="form.$getError('specialisation')"
            @input="form.$clearError('specialisation')"
          />
        </v-col>
        <v-col cols="6">
          <ContentEditor
            v-model="form.introduction"
            class="content-editor"
            :class="{ 'has-error': form.$getError('introduction') }"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('avatar') }"
            @click="uploadThumbnail('avatar')"
            v-if="!checkAvatarThumbNail(form)"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary">
                Upload Avatar
              </span>
            </div>
          </div>
          <v-hover>
            <template v-slot:default="{ hover }">
              <div
                class="upload-container pointer"
                @click="uploadThumbnail('avatar')"
                v-if="checkAvatarThumbNail(form)"
              >
                <v-img
                  :src="form.avatar[0].url"
                  alt="File Upload Placeholder"
                  height="270px"
                  width="auto"
                />

                <v-fade-transition>
                  <v-overlay v-if="hover" absolute>
                    <div class="upload-label mt-3">
                      <span variant="primary">
                        Upload Avatar
                      </span>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </div>
            </template>
          </v-hover>
          <!-- // -->
          <div
            class="upload-container pointer mt-5"
            :class="{ 'has-error': form.$getError('introduction_video') }"
            @click="uploadThumbnail('introduction_video')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary">
                Upload Introduction Video
              </span>
            </div>

            <v-overlay :absolute="true" :value="uplodaLoader">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>
          <div
            class="upload-container pointer mt-5"
            @click="uploadThumbnail('introduction_video')"
            v-if="checkIVideoThumbNail(form)"
          >
            <video controls :src="form.introduction_video[0].url">
              Your browser does not support the video tag.
            </video>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            class="upload-container pointer"
            :class="{ 'has-error': form.$getError('gallery') }"
            @click="uploadThumbnail('gallery')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary">
                Upload Gallery
              </span>
            </div>
          </div>

          <v-row class="mt-5">
            <v-col
              v-for="(photo, index) in gallery"
              :key="index"
              cols="4"
              class="image-container"
            >
              <v-img
                :src="photo.url"
                height="150"
                contain
                class="grey darken-4"
              />
              <v-btn
                fab
                class="radius__button text-none remove-img error"
                x-small
                depressed
                @click="removeImage(index)"
              >
                <v-icon small color="white">
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
      :mediaType="mediaType"
      @startUpload="startUpload"
    ></MediaUploader>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ContentEditor from '@/components/ContentEditor'
import { mdiDelete, mdiClose } from '@mdi/js'
import { objectToFormData } from '@/utils/jsonToFormData'
import MediaUploader from '@/components/MediaUploader'

export default {
  name: 'CreatePractitioner',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    ContentEditor,
    MediaUploader
  },
  computed: {
    ...mapState('practitioner', {
      form: state => state.practitionerForm,
      practitionerLoading: state => state.practitionerLoading
    })
  },
  data() {
    return {
      icons: {
        mdiDelete,
        mdiClose
      },
      imageState: null,
      gallery: [],
      mediaType: null,
      uplodaLoader: false
    }
  },
  mounted() {
    this.form.$reset()
  },
  methods: {
    ...mapActions({
      createPractitioner: 'practitioner/createPractitioner'
    }),
    update() {},
    uploadThumbnail(state) {
      if (state === 'introduction_video') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }

      this.$refs.triggerUpload.openFile()
      this.imageState = state
    },
    startUpload() {
      this.uplodaLoader = true
    },
    onMediaLoad(data) {
      switch (this.imageState) {
        case 'avatar':
          this.form.avatar = [
            {
              file: data.file.get('file'),
              url: data.url
            }
          ]
          break
        case 'introduction_video':
          this.form.introduction_video = []
          this.form.introduction_video = [
            {
              file: data.file.get('file'),
              url: data.url
            }
          ]
          this.uplodaLoader = false
          break
        case 'gallery':
          this.gallery.push({
            file: data.file.get('file'),
            url: data.url
          })
          break
      }
    },
    removeImage(index) {
      this.form.gallery.splice(index, 1)
    },
    async submit() {
      this.form.gallery = this.gallery.map(image => {
        image = image.file
        return image
      })

      const { avatar, introduction_video, ...practitioner } = this.form.$data()
      const form = objectToFormData(practitioner)
      form.append('avatar', avatar[0]?.file)
      form.append('introduction_video', introduction_video[0]?.file)

      const res = await this.createPractitioner(form)
      if (res.status === 201) {
        this.gallery = []
      }
    },
    checkAvatarThumbNail(image) {
      return image?.avatar?.[0]?.url || image?.avatar?.url
    },
    checkIVideoThumbNail(image) {
      return (
        image?.introduction_video?.[0]?.url || image?.introduction_video?.url
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.image-container {
  max-height: 140px;
  width: auto;
  position: relative;
}

.remove-img {
  position: absolute;
  top: 0;
  right: 0;
}

.content-editor {
  position: relative;
  &.has-error {
    border: 2px solid #fa4856;
  }
}

.upload-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #fa4856;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }

  video {
    height: auto;
    width: 100%;
  }
}
</style>
